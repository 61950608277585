import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import Head from 'next/head';
import React from 'react';
import '@assets/styles/global.scss';
import { DefaultSeo } from 'next-seo';
import seo from '../../config/next-seo';

library.add(fab, fas);

// we disable eslint type rules here since the NextJS app instance has very
// dynamic properties and cannot be easily type-hinted

// eslint-disable-next-line react/prop-types
const App = ({ Component, pageProps, store, err }) => {
    return (
        <React.Fragment>
            <Head>
                <meta charSet="utf-8" />
                <meta
                    key="viewport"
                    name="viewport"
                    content="width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1.0, user-scalable=0"
                />
            </Head>

            <DefaultSeo {...seo} />
            <Component {...pageProps} err={err} />
        </React.Fragment>
    );
};

export default App;
