export default {
    title: 'Realm',
    description: 'Architectural Workshop',
    openGraph: {
        type: 'website',
        locale: 'en_US',
        url: 'https://www.realmworkshop.co/',
        // eslint-disable-next-line @typescript-eslint/camelcase
        site_name: 'Realm',
        images: [
            {
                url: 'https://www.realmworkshop.co/background-2.jpg',
                width: 1200,
                height: 675,
                alt: 'Realm OG Image',
            },
        ],
    },
    twitter: {
        handle: '@handle',
        site: '@site',
        cardType: 'summary_large_image',
    },
};
